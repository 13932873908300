import styled from '@emotion/styled'
import { MEDIA_QUERIES } from 'constants/ThemeConstant'

export const GlobalStyle = styled.div`
    @media ${MEDIA_QUERIES.MOBILE} {
        .mobFixPosRemove {
            position: static !important;
        }
        .respoTblScrlHndl.ant-table-wrapper{
            overflow-x: hidden !important;
        }
    }
    .werehouseTypeClient,
    .stateSelect,
    .werehouseTypeClient .ant-select-selection-overflow{
        min-height: 36px;
    }
    .fileArrSpace{
        .ant-space-item:first-child,
        .ant-space-item:nth-child(2){
            flex: 1 !important;
        }
    }
    .werehouseTypeClient .ant-select-selection-item {
        margin: 4px;
    }
`